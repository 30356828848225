import React, { lazy, Suspense } from 'react';

import {
    BrowserRouter as Router,
    Outlet,
    Route,
    Routes,
} from 'react-router-dom';

import Preloader from './components/preloader';
import Layout from './layouts';
import PublicationPage from './pages/publication';
import UnderConstruction from './pages/under-construction';
import Database from './pages/apps/db';
import ElectronicAip from './pages/apps/eaip';
import Obstacles from './pages/apps/obstacles';
import DigitalNotam from './pages/apps/dnotam';
import Visualisation from './pages/apps/gis';
import SchoolPractice from './pages/apps/edu';

const Home = lazy(() => import('./pages/home'));
const Publications = lazy(() => import('./pages/publications'));
const Error400 = lazy(() => import('./pages/error-400'));
const Error404 = lazy(() => import('./pages/error-404'));
const Error500 = lazy(() => import('./pages/error-500'));

const App = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route
                            element={
                                <Layout>
                                    <Outlet />
                                </Layout>
                            }
                        >
                            <Route path='/' element={<Home />} />
                            <Route
                                path='/publications'
                                element={<Publications />}
                            />
                            <Route
                                path='/publications/category/:id'
                                element={<UnderConstruction />}
                            />

                            <Route
                                path='/publication/:id'
                                element={<PublicationPage />}
                            />
                            <Route
                                path='/hub'
                                element={<UnderConstruction />}
                            />

                            <Route path='/apps/db' element={<Database />} />
                            <Route
                                path='/apps/eaip'
                                element={<ElectronicAip />}
                            />
                            <Route
                                path='/apps/obstacles'
                                element={<Obstacles />}
                            />
                            <Route
                                path='/apps/dnotam'
                                element={<DigitalNotam />}
                            />
                            <Route
                                path='/apps/gis'
                                element={<Visualisation />}
                            />
                            <Route
                                path='/apps/edu'
                                element={<SchoolPractice />}
                            />

                            <Route
                                path='/apps/*'
                                element={<UnderConstruction />}
                            />

                            <Route
                                path='/research'
                                element={<UnderConstruction />}
                            />
                            <Route path='/error-400' element={<Error400 />} />
                            <Route path='/error-404' element={<Error404 />} />
                            <Route path='/error-500' element={<Error500 />} />
                        </Route>

                        {/* 404 Page Route */}
                        <Route
                            element={
                                <Layout>
                                    <Outlet />
                                </Layout>
                            }
                        >
                            <Route path='*' element={<Error404 />} />
                        </Route>
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
