import pic3 from '../images/publications/aeronav.png';

const pub2 = {
    id: 3,
    title: 'Архитектура аэронавигационной системы',
    subtitle: 'Архитектура системы управления аэронавигационной информацией',
    image: pic3,
    copyright:
        '<a href="https://www.freepik.com/free-vector/operators-controlling-aircraft-flat-illustration-cartoon-characters-sitting-airport-command-room_12291213.htm#query=aerounatical%20data&position=2&from_view=search&track=ais">Image by pch.vector</a> on Freepik',
    content: `<h4>Введение</h4>
<p>
Сложившаяся система обеспечения аэронавигационной информацией, 
ориентированная на использование бумажных носителей, требует улучшений,
направленных на внедрение электронных носителей для всех органов службы 
аэронавигационной информации (САИ). С целью внедрения этого принципа
разрабатывается автоматизированная система (КАС) аэронавигационной 
информации (АНИ) (рис. 1).
</p>

<img src='/pubs/p2/pic1.jpg' alt='' style='margin-bottom: 8px' />    
<div style='text-align: center; margin-bottom: 20px'>
    <b>Рис. 1.</b> Структура автоматизированной системы аэронавигационной информации
</div>

<h4>Варианты организации системы управления аэронавигационной информацией</h4>
<p>Пользователи системы могут быть условно разделены на четыре основные группы:
поставщики АНИ - оригинаторы, органы контроля (регистрации, согласования,
утверждения), Центр аэронавигационной информации, организующий хранение и 
предоставление АНИ, и наконец, собственно пользователи аэронавигационной
информации и данных.</p>

<p>
Система подготовки данных может быть организована по централизованному и 
децентрализованному принципу.
</p>

<p>
При децентрализованной организации каждый оригинатор и органы контроля
(регистрации, согласования, утверждения) в процессе подготовки АНИ оснащены 
собственными хранилищами локальных данных, которые по мере обновления 
оригинаторами и одобрения информации синхронизируются (обновляют) данные 
в центральном хранилище ЦАИ.
</p>

<p>
При централизованной организации соответствующие локальные хранилища
организуются виртуально в ЦАИ и по мере изменения оригинаторами и одобрения
контролирующими органами обновляют информацию в центральном хранилище. Доступ
к виртуальным хранилищам может быть организован с использованием web-технологий.
Механизм обновления данных в центральном хранилище идентичен для обоих
вариантов и соответствует модели AIXM.
</p>

<p>
Оценивая достоинства и недостатки вариантов построения подсистемы подготовки
данных, можно отметить следующее.</p>

<p>
Децентрализованный вариант требует затрат на оснащение местных подсистем
хранилищами данных и в целом повторяет существующую бумажную технологию
документооборота в службе АНИ. Вместе с тем, использование механизмов
синхронизации данных позволяет обмениваться только 'разницей' предшествующих и
обновленных данных, что существенно снижает нагрузку на сетевую инфраструктуру.
Кроме того, органы, осуществляющие подготовку изменений данных (аэропорты,
центры ОВД), как правило, являются и потребителями данных. В этом случае
локальное хранилище может использоваться для хранения официальных
аэронавигационных данных, необходимых как для производственной деятельности,
так и для подготовки изменений АНИ.
</p>

<p>
Централизованный и децентрализованный варианты могут отличаться по степени
распределения хранилищ и клиентских подсистем. Рассмотрим три основных
варианта реализации КАС АНИ, сохраняя основные принципы циркуляции данных в
системе (рис. 2).
</p>

<img src='/pubs/p2/pic2.jpg' alt='' style='margin-bottom: 8px' />    
<div style='text-align: center; margin-bottom: 20px'>
    <b>Рис. 2.</b> Функциональная схема автоматизированной системы аэронавигационной информации
</div>

<p>
Первоначальным вариантом реализации системы является технологическая структура,
где программно-аппаратный комплекс на каждом уровне системы реализует серверное
решение (сПАК) и не подразумевает использование терминальных технологий.
</p>

<p>
При такой реализации каждый элемент системы - ПАК включает базу данных, в
которой хранятся/редактируются/обрабатываются/предоставляются аэронавигационные
данные. Каждый аэропорт на местном уровне, исходя из анализа информационных
потоков САИ, включает два элемента – ПАК АНИ аэродрома и ПАК АНИ по ОВД 
аэродрома.
</p>

<p>
Особенностью такой реализации является принцип распределенного хранения данных.
Каждый аэродромный ПАК системы при этом непосредственно хранит все данные,
которые относятся к зоне ответственности данного органа САИ. Эти данные
повторяются на соответствующем ПАК регионального уровня, за которым закреплены
все аэропортовые службы данного региона. Федеральные ПАК также повторяют данные
низлежащих уровней. ГБД АНИ объединяет все аэронавигационные данные системы.
</p>

<p>
Другим вариантом (рис. 3) реализации системы является технологическая структура,
реализующая на местном (аэродромном) уровне терминальные ПАК. Уровень региона
и центральное хранилище реализованы в виде серверных ПАК. Каждый ПАК органа САИ
на уровне аэродрома связывается с региональной базой данных на основе
терминальной технологии. Региональный ПАК содержит информацию по
соответствующему региону, а ЦАИ – государственную базу АНИ.
</p>

<img src='/pubs/p2/pic3.png' alt='' style='margin-bottom: 8px' />    
<div style='text-align: center; margin-bottom: 20px'>
    <b>Рис. 3.</b> Вариант реализации системы с терминалом на местном уровне
</div>

<p>
Крайним централизованным вариантом реализации системы является технологическая
структура, реализующая на местном (аэродромном), региональном и федеральном
уровне тПАК. В результате в системе реализуется единая централизованная база
данных аэронавигационной информации, существующая в единственном числе. Все
потоки данных местных и региональных уровней, а также поток данных федерального
уровня и сторонних систем циркулируют через центральный банк данных АНИ
(рис. 4).
</p>
<img src='/pubs/p2/pic4.png' alt='' style='margin-bottom: 8px' />    
<div style='text-align: center; margin-bottom: 20px'>
    <b>Рис. 4.</b> Вариант реализации системы с централизованной ГБД АНИ
</div>
<p>
Каждый из отмеченных выше вариантов построения КАС АНИ требует детальной проработки в связи с необходимостью компромиссного решения между стоимостью и сложностью реализации терминальных и серверных ПАК на различных уровнях. Кроме того, важным фактором является наличие широкополосных каналов связи, особенно на местном уровне. Следует также принимать во внимание различия в нагрузке, создаваемой терминальными системами на серверные, особенно при построении полностью централизованной базы данных.
</p>

<p>
Важным фактором также является выбранный способ распределения и синхронизации данных между базами данных различных уровней:
</p>

<ul style='margin: 18px; padding: initial; list-style: initial'>
    <li>полная синхронизация</li>
    <li>сегментная синхронизация</li>
</ul>
    
<p>
Полная синхронизация подразумевает полное копирование базы данных между всеми
элементами системы. При такой синхронизации данные, записанные или измененные на одном из элементов системы, записываются/меняются во всех хранилищах данных других элементах системы. В результате на всех базах данных - полная копия данных всех элементов системы. При этом нагрузка на сетевую инфраструктуру максимальна.
</p>

<p>
Сегментная синхронизация подразумевает обмен только «разностями», что снижает нагруз- ку на сеть, но требует дополнительных механизмов анализа и обновления.
</p>

<h4>Выводы</h4>
<p>
Возможны различные подходы к построению системы управления аэронавигационной информацией, предъявляющие различные требования к аппаратным, программным средствам и сетевой подсистеме.
</p>
<p>
Оценка вариантов построения системы управления аэронавигационной информацией является актуальной задачей и должна учитывать необходимость своевременной доставки потребителям корректной и целостной информации.
</p>
`,
};

export default pub2;
