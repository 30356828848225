import {
    Media,
    MediaBody,
    Anchor,
    Avatar,
    AvatarInitial,
} from '@doar/components';
import img_vak from '@doar/shared/images/publications/vak.jpg';
import img_aeronav from '@doar/shared/images/publications/aeronav.png';
import WidgetTitle from '../widget-title';
import { StyledName, StyledRole } from './style';

const PopularGroups = () => {
    return (
        <>
            <WidgetTitle title='Направления' />
            <Media as='ul' mb='15px'>
                <Media as='li' alignItems='center'>
                    <Anchor path='#!'>
                        <Avatar>
                            <img src={img_vak} alt='avatar' />
                        </Avatar>
                    </Anchor>
                    <MediaBody ml='15px'>
                        <StyledName>
                            <Anchor path='#!' color='text'>
                                ВАК Публикации
                            </Anchor>
                        </StyledName>
                        <StyledRole>Статьи</StyledRole>
                    </MediaBody>
                </Media>
                <Media as='li' alignItems='center' mt='15px'>
                    <Anchor path='#!'>
                        <Avatar>
                            <img src={img_aeronav} alt='avatar' />
                        </Avatar>
                    </Anchor>
                    <MediaBody ml='15px'>
                        <StyledName>
                            <Anchor path='#!' color='text'>
                                Аэронавигация
                            </Anchor>
                        </StyledName>
                        <StyledRole>Статьи</StyledRole>
                    </MediaBody>
                </Media>
                <Media as='li' alignItems='center' mt='15px'>
                    <Anchor path='#!'>
                        <Avatar>
                            <AvatarInitial bg='gray500'>B</AvatarInitial>
                        </Avatar>
                    </Anchor>
                    <MediaBody ml='15px'>
                        <StyledName>
                            <Anchor path='#!' color='text'>
                                Форматы обмена АНИ
                            </Anchor>
                        </StyledName>
                        <StyledRole>Статьи</StyledRole>
                    </MediaBody>
                </Media>
                <Media as='li' alignItems='center' mt='15px'>
                    <Anchor path='#!'>
                        <Avatar>
                            <AvatarInitial bg='indigo'>B</AvatarInitial>
                        </Avatar>
                    </Anchor>
                    <MediaBody ml='15px'>
                        <StyledName>
                            <Anchor path='#!' color='text'>
                                Моделирование
                            </Anchor>
                        </StyledName>
                        <StyledRole>Статьи</StyledRole>
                    </MediaBody>
                </Media>
                <Media as='li' alignItems='center' mt='15px'>
                    <Anchor path='#!'>
                        <Avatar>
                            <AvatarInitial bg='pink'>М</AvatarInitial>
                        </Avatar>
                    </Anchor>
                    <MediaBody ml='15px'>
                        <StyledName>
                            <Anchor path='#!' color='text'>
                                Программирование
                            </Anchor>
                        </StyledName>
                        <StyledRole>Статьи</StyledRole>
                    </MediaBody>
                </Media>
            </Media>
        </>
    );
};

export default PopularGroups;
