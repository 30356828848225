// import pic2 from '../images/publications/air.png';
import pub1 from './pub1';
import pub2 from './pub2';

export interface Publication {
    id: number;
    title: string;
    subtitle: string;
    image: string;
    copyright?: string;
    content: string;
}

const dataPublications = [pub1, pub2];

export default dataPublications;
