import {
    Card,
    CardBody,
    CardImage,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from '@doar/components';
import Content from '../../layouts/content';
import PageHeader from '../../components/page-header';
import SEO from '../../components/seo';
import React from 'react';

import { StyledWrap } from '../../components/publications/sidebar/style';
import PopularGroups from '../../components/widgets/popular-groups';
import database from '../../images/apps/database.jpg';

const ElectronicAip = () => {
    return (
        <>
            <SEO />
            <Content borderBottomWidth='1px'>
                <PageHeader
                    prev={[
                        { text: 'Главная', link: '/' },
                        { text: 'Разработки', link: '/apps' },
                    ]}
                    title='Электронный AIP'
                    wcText='Электронный AIP'
                />
            </Content>
            <Content mt={[null, null, null, '0px']}>
                <Row>
                    <Col lg={9} xl={9}>
                        <Card width={['100%', '100%']}>
                            <CardBody>
                                <CardTitle>Электронный AIP</CardTitle>
                                <CardText>
                                    <p>
                                        ПО предоставляет сборник
                                        аэронавигационной информации в
                                        электронном формате для визуализации его
                                        содержания на экране компьютера, с
                                        использованием базы данных
                                        аэронавигационной информации.
                                    </p>
                                    <p>
                                        Проводились исследования и анализ
                                        отечественных нормативных документов,
                                        международных стандартов и рекомендаций
                                        по аэронавигационной информации,
                                        изучение структуры и форматов
                                        электронных сборников аэронавигационной
                                        информации как отечественных, так и
                                        международных.
                                    </p>
                                    <p>Особенности:</p>
                                    <p>
                                        <ul
                                            style={{
                                                margin: '18px',
                                                padding: 'initial',
                                                listStyle: 'initial',
                                            }}
                                        >
                                            <li>
                                                Форма и структура АИП,
                                                независимо от того, бумажная ли
                                                это версия, цифровая или
                                                электронная, разрабатываются в
                                                соответствии со Стандартами и
                                                Рекомендуемой практикой (SARPS),
                                                изложенными в Приложении 15
                                                «Службы аэронавигационной
                                                информации» к Конвенции о
                                                международной гражданской
                                                авиации, и документом ИКАО 8126
                                                «Руководство по службам
                                                аэронавигационной информации».
                                            </li>
                                            <li>
                                                Программное обеспечение сборника
                                                АНИ основано на принятой ИКАО
                                                концептуальной модели
                                                аэронавигационной информации
                                                ACIM.
                                            </li>
                                        </ul>
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3} mt={['40px', null, null, '0px']}>
                        <StyledWrap>
                            <PopularGroups />
                        </StyledWrap>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default ElectronicAip;
