import { Image } from '@doar/components';
import { StyledLogo } from './style';
import logo from '../../images/logo.png';

const Logo = () => {
    return (
        <StyledLogo>
            <Image src={logo} alt='sky.logiq' fit='cover' />
            {/*sky<span>.logiq.one</span>*/}
        </StyledLogo>
    );
};

export default Logo;
