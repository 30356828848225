import {
    Card,
    CardBody,
    CardImage,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from '@doar/components';
import Content from '../../layouts/content';
import PageHeader from '../../components/page-header';
import SEO from '../../components/seo';
import React from 'react';

import { StyledWrap } from '../../components/publications/sidebar/style';
import PopularGroups from '../../components/widgets/popular-groups';
import database from '../../images/apps/database.jpg';

const Database = () => {
    return (
        <>
            <SEO />
            <Content borderBottomWidth='1px'>
                <PageHeader
                    prev={[
                        { text: 'Главная', link: '/' },
                        { text: 'Разработки', link: '/apps' },
                    ]}
                    title='База данных аэронавигационной информации'
                    wcText='База данных аэронавигационной информации'
                />
            </Content>
            <Content mt={[null, null, null, '0px']}>
                <Row>
                    <Col lg={9} xl={9}>
                        <Card width={['100%', '100%']}>
                            <CardImage src={database} alt='card' isTop />
                            <div
                                style={{
                                    padding: '5px 20px 0',
                                    textAlign: 'left',
                                }}
                            >
                                <a href='http://www.freepik.com'>
                                    Designed by fullvector / Freepik
                                </a>
                            </div>

                            <CardBody>
                                <CardTitle>
                                    База данных аэронавигационной информации
                                </CardTitle>
                                <CardSubtitle>
                                    Аэронавигационная база данных с поддержкой
                                    AICM/AIXM/AIRINC-424
                                </CardSubtitle>
                                <CardText>
                                    <p>
                                        База данных представляет собой
                                        физическую реализацию реализацию
                                        концептуальной модели аэронавигационной
                                        информации AICM. Реализация обеспечивает
                                        расширяемость отношений и дополнительных
                                        объектов или атрибутов в соответствии с
                                        национальными и другими требованиями,
                                        например, требований авиакомпаний. База
                                        данных является полностью временной, что
                                        означает, что она способна обрабатывать
                                        текущую версию данных вместе с прошлыми
                                        и будущими версиями и отображать полную
                                        историю всех изменений. Чтобы повысить
                                        целостность данных и избежать
                                        человеческих ошибок, предусмотрена
                                        концепция, в котором все критические для
                                        эксплуатации изменения должны быть
                                        введены двумя операторами и могли
                                        вступить в силу только в том случае,
                                        если записи одинаковы.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3} mt={['40px', null, null, '0px']}>
                        <StyledWrap>
                            <PopularGroups />
                        </StyledWrap>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default Database;
