import {
    Button,
    Card,
    CardBody,
    CardImage,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from '@doar/components';
import Content from '../../layouts/content';
import PageHeader from '../../components/page-header';
import SEO from '../../components/seo';
import React from 'react';

import { StyledWrap } from '../../components/publications/sidebar/style';
import PopularGroups from '../../components/widgets/popular-groups';
import database from '../../images/apps/database.jpg';

const SchoolPractice = () => {
    return (
        <>
            <SEO />
            <Content borderBottomWidth='1px'>
                <PageHeader
                    prev={[
                        { text: 'Главная', link: '/' },
                        { text: 'Разработки', link: '/apps' },
                    ]}
                    title='Учебная практика'
                    wcText='Организация стажировки в учебном процессе'
                />
            </Content>
            <Content mt={[null, null, null, '0px']}>
                <Row>
                    <Col lg={9} xl={9}>
                        <Card width={['100%', '100%']}>
                            <CardBody>
                                <CardTitle>
                                    Организация стажировки в учебном процессе
                                </CardTitle>
                                <CardText>
                                    <p>
                                        Стажировка является индивидуальной
                                        формой дополнительного профессионального
                                        образования Преподавателей. Главной
                                        задачей стажировки является обновление и
                                        углубление знаний в профессиональной
                                        областях.
                                    </p>
                                    <Button
                                        color='success'
                                        onClick={() => {
                                            window.open(
                                                'https://skolpraktiken.logiq.one/',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Демо версия
                                    </Button>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3} mt={['40px', null, null, '0px']}>
                        <StyledWrap>
                            <PopularGroups />
                        </StyledWrap>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default SchoolPractice;
