import Content from '../layouts/content';
import SEO from '../components/seo';
import {
    StyledDesc,
    StyledImg,
    StyledNote,
    StyledSubTitle,
    StyledTitle,
    StyledWrap,
} from './error-404.styles';
import image from '../images/under-construction2.png';

const UnderConstruction = () => {
    return (
        <>
            <SEO />
            <Content fullHeight align='center'>
                <StyledWrap>
                    <StyledImg>
                        <img src={image} alt='400' />
                    </StyledImg>
                    <StyledTitle>В процессе разработки...</StyledTitle>
                    <StyledSubTitle>
                        Страницу, которую Вы ищите, в процессе разработки
                    </StyledSubTitle>
                    <StyledDesc>Обновления ожидаются в будущем</StyledDesc>
                    <StyledNote>
                        Векторное изображение{' '}
                        <a
                            href='https://www.freepik.com'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            designed by Storyset - Freepik.com
                        </a>
                    </StyledNote>
                </StyledWrap>
            </Content>
        </>
    );
};

export default UnderConstruction;
