import {
    Card,
    CardBody,
    CardImage,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from '@doar/components';
import Content from '../../layouts/content';
import PageHeader from '../../components/page-header';
import SEO from '../../components/seo';
import React from 'react';

import { StyledWrap } from '../../components/publications/sidebar/style';
import PopularGroups from '../../components/widgets/popular-groups';
import database from '../../images/apps/database.jpg';

const Obstacles = () => {
    return (
        <>
            <SEO />
            <Content borderBottomWidth='1px'>
                <PageHeader
                    prev={[
                        { text: 'Главная', link: '/' },
                        { text: 'Разработки', link: '/apps' },
                    ]}
                    title='Учёт аэронаивгационных препятствий'
                    wcText='Учёт аэронаивгационных препятствий'
                />
            </Content>
            <Content mt={[null, null, null, '0px']}>
                <Row>
                    <Col lg={9} xl={9}>
                        <Card width={['100%', '100%']}>
                            <CardBody>
                                <CardTitle>
                                    Учёт аэронавигационных препятствий
                                </CardTitle>
                                <CardText>
                                    <p>
                                        Массивы электронных данных о
                                        препятствиях, используемые в сочетании с
                                        аэронавигационными данными, должны
                                        отвечать требованиям пользователей.
                                        Среди них можно выделить несколько
                                        областей по следующим видам применения
                                        электронных данных о препятствиях,
                                        связанных с аэронавигацией:
                                    </p>
                                    <ul
                                        style={{
                                            margin: '18px',
                                            padding: 'initial',
                                            listStyle: 'initial',
                                        }}
                                    >
                                        <li>
                                            система предупреждения о близости
                                            земли с функцией оценки рельефа
                                            местности в направлении полета
                                            (GPWS) и система предупреждения о
                                            минимальной безопасной абсолютной
                                            высоте (MSAW);
                                        </li>

                                        <li>
                                            определение запасных схем для
                                            использования в случае аварийной
                                            ситуации при уходе на второй круг
                                            или взлете;
                                        </li>

                                        <li>
                                            анализ эксплуатационных ограничений
                                            воздушного судна;
                                        </li>

                                        <li>
                                            построение схем полетов по приборам
                                            (включая схему полета по кругу);
                                        </li>

                                        <li>
                                            определение процедуры снижения при
                                            полете по маршруту и места аварийной
                                            посадки;
                                        </li>

                                        <li>
                                            усовершенствованная система
                                            управления наземным движением и
                                            контроля за ним (A-SMGCS);
                                        </li>

                                        <li>
                                            составление аэронавигационных карт и
                                            бортовые базы данных;
                                        </li>

                                        <li>летный тренажер;</li>

                                        <li>синтезированная визуализация;</li>
                                        <li>
                                            ограничение и устранение препятствий
                                            на аэродроме/вертодроме.
                                        </li>
                                    </ul>
                                    <p>
                                        С целью выполнения требований, связанных
                                        с использованием аэронавигационных
                                        систем, массивы электронных данных о
                                        местности и препятствиях составляются и
                                        заносятся в базы данных для следующих
                                        районов:
                                    </p>
                                    <ul
                                        style={{
                                            margin: '18px',
                                            padding: 'initial',
                                            listStyle: 'initial',
                                        }}
                                    >
                                        <li>
                                            <b>район 1:</b> вся территория
                                            государства;
                                        </li>
                                        <li>
                                            <b>район 2:</b> узловой
                                            диспетчерский район;
                                        </li>
                                        <li>
                                            <b>район 3:</b> район
                                            аэродрома/вертодрома;{' '}
                                        </li>
                                        <li>
                                            <b>район 4:</b> район операций по
                                            категории II или III.
                                        </li>
                                    </ul>
                                    <p>
                                        <i>Район 1</i> охватывает всю территорию
                                        государства, включая
                                        аэродромы/вертодромы.
                                    </p>
                                    <p>
                                        Район 2 представляет собой узловой
                                        диспетчерский район, указанный в
                                        сборнике аэронавигационной информации
                                        (AIP) государства или ограниченный
                                        радиусом 45 км от контрольной точки
                                        аэродрома/вертодрома, в зависимости от
                                        того, что меньше. На
                                        аэродромах/вертодромах, где
                                        обеспечиваются операции по ППП и где
                                        узловой диспетчерский район не
                                        установлен, район 2 представляет собой
                                        зону в пределах радиуса 45 км от
                                        контрольной точки аэродрома/вертодрома.
                                    </p>
                                    <p>
                                        На аэродромах/вертодромах, где
                                        обеспечиваются операции по ППП, район
                                        охватывает зону, которая простирается от
                                        боковой кромки (кромок) одной
                                        (нескольких) ВПП до 90 м от осевой линии
                                        (осевых линий) ВПП, а для всех других
                                        частей рабочей площади
                                        аэродрома/вертодрома на 50 м от боковой
                                        кромки (кромок) установленной зоны
                                        (зон).
                                    </p>
                                    <p>
                                        Район 4 предусматривается только на тех
                                        ВПП, где выполняются точные заходы на
                                        посадку по категориям II или III и где
                                        эксплуатантам требуется детальная
                                        информация о местности, позволяющая им
                                        оценить влияниеместности на установление
                                        высоты принятия решения при
                                        использовании радиовысотомеров. Ширина
                                        района равняется 60 м с каждой стороны
                                        продленной осевой линии ВПП, а длина
                                        составляет 900 м от порога ВПП и
                                        измеряется вдоль продленной осевой линии
                                        ВПП.
                                    </p>
                                    <p>
                                        База данных о препятствиях содержит
                                        массив цифровых данных о препятствиях и
                                        включает те элементы, которые
                                        возвышаются над прилегающими и
                                        окружающими элементами и считаются
                                        опасными для аэронавигации. Данные о
                                        препятствиях предусматривают цифровое
                                        представление вертикальных и
                                        горизонтальных размеров искусственных
                                        объектов. Препятствия не включаются в
                                        базы данных о местности. Компоненты
                                        данных о препятствиях представляют собой
                                        элементы, которые отображаются в базе
                                        данных точками, линиями или
                                        многоугольниками.
                                    </p>
                                    <p>
                                        Препятствия могут быть неподвижными
                                        (постоянными или временными) или
                                        подвижными. В базу данных о препятствиях
                                        включаются все типы элементов,
                                        определенные в качестве препятствий, и
                                        каждый из них описывается в соответствии
                                        с перечнем обязательных атрибутов.
                                    </p>
                                    <p>
                                        Для предоставления различным поставщикам
                                        и пользователям данных возможности
                                        обмениваться массивами электронных
                                        данных о препятствиях, в качестве общей
                                        основы типового формирования данных
                                        используются стандарты ИСО серии 19100,
                                        касающиеся географической информации.
                                    </p>
                                    <p>
                                        Подробное описание имеющихся массивов
                                        электронных данных о препятствиях
                                        составляется в виде спецификаций
                                        информационных продуктов с данными о
                                        местности и спецификаций информационных
                                        продуктов с данными о препятствиях, на
                                        основе которых аэронавигационные
                                        пользователи могут оценить эти продукты
                                        и определить, отвечают ли они
                                        требованиям к их планируемому
                                        использованию (применению).
                                    </p>
                                    <p>
                                        Применительно к географической
                                        информации стандарт 19131 ИСО определяет
                                        требования к спецификациям
                                        информационных продуктов и их
                                        содержание.
                                    </p>
                                    <p>
                                        Программный продукт реализует функционал
                                        по вводу, редактированию и отображению
                                        данных по искусственным препятсвиям и,
                                        содержащего базу данных хранения
                                        аэронавигационных данных по препятсвиям.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3} mt={['40px', null, null, '0px']}>
                        <StyledWrap>
                            <PopularGroups />
                        </StyledWrap>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default Obstacles;
