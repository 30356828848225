import { Helmet } from 'react-helmet';

interface IProps {
    title?: string;
    titleTemplate?: string;
    description?: string;
}

const SEO = ({ title, titleTemplate, description }: IProps) => {
    return (
        <Helmet>
            <meta charSet='utf-8' />
            <title>
                {title} - {titleTemplate}
            </title>
            <meta name='description' content={description} />
        </Helmet>
    );
};

SEO.defaultProps = {
    title: 'Исследования и разработка',
    titleTemplate: 'sky.logiq.one',
    description:
        'Исследования и разработка в областях аэронавигационного обслуживания, информационных систем и прикладной математики',
};

export default SEO;
