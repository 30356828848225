import React from 'react';
import {
    Card,
    CardBody,
    CardImage,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from '@doar/components';
import Content from '../layouts/content';
import PageHeader from '../components/page-header';
import SEO from '../components/seo';
import { Navigate, useParams } from 'react-router-dom';

import dataPublications from '@doar/shared/data/data-publications';
import { StyledWrap } from '../components/publications/sidebar/style';
import PopularGroups from '../components/widgets/popular-groups';
import UnderConstruction from './under-construction';

const PublicationPage = () => {
    const { id } = useParams();

    console.log(id);

    if (!id || +id < 1) {
        return <Navigate replace to='/error-400' />;
    }

    if (isNaN(+id)) {
        return <UnderConstruction />;
    }

    const publication = dataPublications.find((p) => p.id === +id);
    if (!publication) {
        return <Navigate replace to='/error-404' />;
    }

    return (
        <>
            <SEO />
            <Content borderBottomWidth='1px'>
                <PageHeader
                    prev={[
                        { text: 'Главная', link: '/' },
                        { text: 'Публикации', link: '/publications' },
                    ]}
                    title={publication.title}
                    wcText={publication.subtitle}
                />
            </Content>
            <Content mt={[null, null, null, '0px']} mb='50px'>
                <Row>
                    <Col lg={9}>
                        <Card width={['100%', '100%']}>
                            <CardImage
                                src={publication.image}
                                alt='card'
                                isTop
                            />
                            {publication.copyright ? (
                                <div
                                    style={{
                                        padding: '5px 20px 0',
                                        textAlign: 'left',
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: publication.copyright,
                                    }}
                                />
                            ) : (
                                <div />
                            )}
                            <CardBody>
                                <CardTitle>{publication.title}</CardTitle>
                                <CardSubtitle>
                                    {publication.subtitle}
                                </CardSubtitle>
                                <br />
                                <CardText>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: publication.content,
                                        }}
                                    ></div>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3} mt={['40px', null, null, '0px']}>
                        <StyledWrap>
                            <PopularGroups />
                        </StyledWrap>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default PublicationPage;
