import { useState } from 'react';
import { Settings } from 'react-feather';
import { Row, Col, Image } from '@doar/components';
import { TTheme } from '@doar/shared/types';
import { SkinModes } from './data';
import Mode from './mode';
import {
    StyledSettings,
    StyledSettingsBtn,
    StyledSettingsBody,
    StyledCard,
    // StyledLogo,
    StyledNote,
    StyledLabel,
    // StyledNavLink,
} from './style';
import logo from '../../images/logo.png';
// import { maxWidth } from 'styled-system';

interface IProps {
    themeHandler: (el: TTheme) => void;
    curTheme: TTheme;
}

const SettingsCard = ({ themeHandler, curTheme }: IProps) => {
    const [show, setShow] = useState(false);
    // const [location, setLocation] = useState('');
    // useEffect(() => {
    //     setLocation(window.location.origin);
    // }, []);

    return (
        <StyledSettings $show={show}>
            <StyledSettingsBtn
                $show={show}
                onClick={() => setShow((prev) => !prev)}
            >
                <Settings width={20} height={20} />
            </StyledSettingsBtn>
            <StyledSettingsBody>
                <StyledCard>
                    <Image
                        src={logo}
                        display={'block'}
                        width={140}
                        alt='sky.logiq'
                        fit='cover'
                    />
                    <StyledNote>Настройки</StyledNote>
                </StyledCard>

                <StyledCard $hasBorder>
                    <StyledLabel>Палитра</StyledLabel>
                    <Row gutters={10}>
                        {SkinModes.map((el, i) => (
                            <Col xs={4} sm={4} key={el} mt={i > 2 ? '10px' : 0}>
                                <Mode
                                    mode={el}
                                    onClick={() => themeHandler(el)}
                                    active={curTheme === el}
                                />
                            </Col>
                        ))}
                    </Row>
                </StyledCard>

                <div className='pd-y-20 bd-t' style={{ display: 'none' }}>
                    <h4 className='tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15'>
                        Navigation Skin
                    </h4>
                    <div className='row row-xs'>
                        <div className='col-4'>
                            <a
                                href='/'
                                className='df-skin df-skin-default active'
                                data-title='default'
                            >
                                df
                            </a>
                            <span className='df-skin-name'>Default</span>
                        </div>
                        <div className='col-4'>
                            <a
                                href='/'
                                className='df-skin df-skin-deepblue'
                                data-title='deepblue'
                            >
                                <span />
                                <span />
                            </a>
                            <span className='df-skin-name'>Deep Blue</span>
                        </div>
                        <div className='col-4'>
                            <a
                                href='/'
                                className='df-skin df-skin-charcoal'
                                data-title='charcoal'
                            >
                                <span />
                                <span />
                            </a>
                            <span className='df-skin-name'>Charcoal</span>
                        </div>
                        <div className='col-4 mg-t-15'>
                            <a
                                href='/'
                                className='df-skin df-skin-gradient1'
                                data-title='gradient1'
                            >
                                <span />
                                <span />
                            </a>
                            <span className='df-skin-name'>Gradient 1</span>
                        </div>
                    </div>
                </div>

                <div className='pd-y-20 bd-t' style={{ display: 'none' }}>
                    <h4 className='tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15'>
                        Font Family Base
                    </h4>
                    <div className='row row-xs'>
                        <div className='col-7'>
                            <a
                                href='/'
                                id='setFontBase'
                                className='btn btn-xs btn-outline-secondary active'
                            >
                                IBM Plex Sans
                            </a>
                        </div>
                        <div className='col-5'>
                            <a
                                href='/'
                                id='setFontRoboto'
                                className='btn btn-xs btn-outline-secondary'
                            >
                                Roboto
                            </a>
                        </div>
                    </div>
                </div>
            </StyledSettingsBody>
        </StyledSettings>
    );
};

export default SettingsCard;
