import {
    AlertTriangle,
    CloudLightning,
    Database,
    Globe,
    Mail,
    Map,
    Navigation,
    PenTool,
    BookOpen,
    Settings,
    Users,
    Home,
    GitPullRequest,
    HardDrive,
} from 'react-feather';

const menus = [
    {
        id: 1,
        url: '/',
        label: 'Главная',
        Icon: Home,
    },
    {
        id: 2,
        label: 'Публикации',
        url: '/publications',
        Icon: BookOpen,
    },
    {
        id: 3,
        label: 'Исследования',
        url: '/research',
        Icon: PenTool,
        submenu: [
            {
                id: 11,
                label: 'ВАК Публикации',
                url: '/publications/category/1',
                Icon: Globe,
            },
            {
                id: 12,
                label: 'Аэронавигация',
                url: '/publications/category/2',
                Icon: Navigation,
            },
            {
                id: 13,
                label: 'Модели обмена АНИ',
                url: '/publications/category/3',
                Icon: Mail,
            },
            {
                id: 14,
                label: 'Аналитические модели',
                url: '/publications/category/4',
                Icon: PenTool,
            },
            {
                id: 15,
                label: 'Имитационные модели',
                url: '/publications/category/5',
                Icon: Settings,
            },
        ],
    },
    {
        id: 4,
        label: 'Разработка',
        url: '/development',
        Icon: GitPullRequest,
        submenu: [
            {
                id: 41,
                label: 'База данных АНИ',
                url: '/apps/db',
                Icon: Database,
            },
            {
                id: 42,
                label: 'Учёт аэронавигационных препятствий',
                url: '/apps/obstacles',
                Icon: AlertTriangle,
            },
            {
                id: 43,
                label: 'Электронный АИП',
                url: '/apps/eaip',
                Icon: Map,
            },
            {
                id: 44,
                label: 'Цифровой НОТАМ',
                url: '/apps/dnotam',
                Icon: CloudLightning,
            },
            {
                id: 45,
                label: 'Геоинформационная система отображения АНИ',
                url: '/apps/gis',
                Icon: Globe,
            },
            {
                id: 46,
                label: 'Организация стажировки в учебном процессе',
                url: '/apps/edu',
                Icon: Users,
            },
        ],
    },
    {
        id: 5,
        label: 'Репозиторий',
        url: '/hub',
        Icon: HardDrive,
    },
];

export default menus;
