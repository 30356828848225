import {
    Card,
    CardBody,
    CardImage,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from '@doar/components';
import Content from '../../layouts/content';
import PageHeader from '../../components/page-header';
import SEO from '../../components/seo';
import React from 'react';

import { StyledWrap } from '../../components/publications/sidebar/style';
import PopularGroups from '../../components/widgets/popular-groups';
import database from '../../images/apps/database.jpg';

const DigitalNotam = () => {
    return (
        <>
            <SEO />
            <Content borderBottomWidth='1px'>
                <PageHeader
                    prev={[
                        { text: 'Главная', link: '/' },
                        { text: 'Разработки', link: '/apps' },
                    ]}
                    title='Цифровой NOTAM'
                    wcText='Цифровой NOTAM'
                />
            </Content>
            <Content mt={[null, null, null, '0px']}>
                <Row>
                    <Col lg={9} xl={9}>
                        <Card width={['100%', '100%']}>
                            <CardBody>
                                <CardTitle>Цифровой НОТАМ</CardTitle>
                                <CardText>
                                    <p>
                                        Цифровой НОТАМ — это набор данных,
                                        доступный в виде цифрового сервиса,
                                        содержащий информацию, касающуюся
                                        создания, состояния или изменения любого
                                        авиационного средства, услуги, процедуры
                                        или опасности, своевременное знание о
                                        которых имеет важное значение для систем
                                        и автоматизированного оборудования,
                                        используемого персоналом, связанным с
                                        полетами.
                                    </p>
                                    <p>
                                        ПО Цифровой НОТАМ сочетает в себе
                                        современный дизайн с цифровыми данными,
                                        повышает эффективность за счет
                                        интуитивно понятного графического
                                        создания данных и визуального контроля,
                                        автоматизирует создание обычных НОТАМ,
                                        повышает качество данных и безопасность.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3} mt={['40px', null, null, '0px']}>
                        <StyledWrap>
                            <PopularGroups />
                        </StyledWrap>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default DigitalNotam;
